import { SectionKeys } from "../useSectionHook";
import { useState } from "react";
import PersonalInformation from "./PersonalInformation";
import Engagement from "./Engagement";
import MediaUpload from "./MediaUpload";

interface Props {
  currentSectionKey: SectionKeys<"FANBASE">;
  onNext: () => void;
  onPrevious: () => void;
  onComplete: () => void;
}

const Fanbase = (props: Props) => {
  const { currentSectionKey, onNext, onPrevious, onComplete } = props;

  const [personalInfo, setPersonalInfo] = useState(null);
  const [professionalIfo, setProfessionalInfo] = useState(null);

  const handlePersonalData = (data: any) => {
    setPersonalInfo(data);
  };
  const handleClubData = (data: any) => {
    setProfessionalInfo(data);
  };



  return (
    <>
      {/* Form 1 */}
      {currentSectionKey == "personalInfo" && (
        <PersonalInformation
        onNext={onNext}
        onSubmitFormData={handlePersonalData}
        />
      )}
      {/* Form 2 */}
      {currentSectionKey == "engagement" && (
        <Engagement
        onNext={onNext}
        onPrevious={onPrevious}
        onSubmitFormData={handleClubData}
        />
      )}
      {/* Form 3 */}
      {currentSectionKey == "mediaUpload" && (
        <MediaUpload
            onComplete={onComplete}
          onPrevious={onPrevious}
               professionalInfo={professionalIfo}
          personalInformation={personalInfo}
      
           />
      )}

    </>
  );
};

export default Fanbase;
