export const SPORTS = [
  "Football (Soccer)",
  "Basketball",
  "Track and Field ",
  "Tennis",
  "Boxing",
  "Swimming",
  "Volleyball",
  "Rugby",
  "Gymnastics",
  "Cricket",
  "Table Tennis",
  "Ice Hockey",
  "Field Hockey",
  "Wrestling",
  "Golf",
  "Badminton",
  "Cycling",
  "Martial Arts (Karate Taekwondo Judo)",
  "Handball",
  "Rowing",
  "Weightlifting",
  "Archery",
  "Fencing",
  "Equestrian Sports",
  "Surfing",
  "Skating",
  "Esports",
  "Indoor Games",
  "Rugby",
  "American Football",
  "Baseball",
];

export const sportsPositions = [

      { position: "Goalkeeper" },
      { position: "Defender", subPositions: ["Center Back", "Full Back", "Wing Back"] },
      { position: "Midfielder", subPositions: ["Central Midfielder", "Defensive Midfielder", "Attacking Midfielder", "Winger"] },
      { position: "Forward", subPositions: ["Striker", "Center Forward", "Second Striker", "Winger"] },
            { position: "Point Guard (PG)" },
      { position: "Shooting Guard (SG)" },
      { position: "Small Forward (SF)" },
      { position: "Power Forward (PF)" },
      { position: "Center (C)" },
            { position: "Quarterback (QB)" },
      { position: "Running Back (RB)" },
      { position: "Wide Receiver (WR)" },
      { position: "Tight End (TE)" },
      { position: "Offensive Lineman (OL)" },
      { position: "Defensive Lineman (DL)" },
      { position: "Linebacker (LB)" },
      { position: "Cornerback (CB)" },
      { position: "Safety (S)" },
      { position: "Kicker/Punter" },
          { position: "Pitcher" },
      { position: "Catcher" },
      { position: "First Baseman" },
      { position: "Second Baseman" },
      { position: "Shortstop" },
      { position: "Third Baseman" },
      { position: "Left Fielder" },
      { position: "Center Fielder" },
      { position: "Right Fielder" },
            { position: "Singles Player" },
      { position: "Doubles Player" },
      { position: "Mixed Doubles Player" },
            { position: "Prop" },
      { position: "Hooker" },
      { position: "Lock" },
      { position: "Flanker" },
      { position: "Number Eight" },
      { position: "Scrum-half" },
      { position: "Fly-half" },
      { position: "Center" },
      { position: "Wing" },
      { position: "Full-back" },
         { position: "Batsman" },
      { position: "Bowler" },
      { position: "Wicket-Keeper" },
      { position: "All-Rounder" },
      { position: "Fielder" },
            { position: "Setter" },
      { position: "Outside Hitter" },
      { position: "Opposite Hitter" },
      { position: "Middle Blocker" },
      { position: "Libero" },
      { position: "Defensive Specialist" },
            { position: "Goalie" },
      { position: "Defenseman" },
      { position: "Center" },
      { position: "Left Wing" },
      { position: "Right Wing" },
            { position: "Goalkeeper" },
      { position: "Defender" },
      { position: "Midfielder" },
      { position: "Forward" },
            { position: "Golfer" },
      { position: "Caddy (Support Role)" },
          { position: "Sprinter" },
      { position: "Long Distance Runner" },
      { position: "Hurdler" },
      { position: "Long Jumper" },
      { position: "High Jumper" },
      { position: "Pole Vaulter" },
      { position: "Shot Putter" },
      { position: "Discus Thrower" },
      { position: "Javelin Thrower" },

            { position: "Heavyweight" },
      { position: "Cruiserweight" },
      { position: "Light Heavyweight" },
      { position: "Middleweight" },
      { position: "Welterweight" },
      { position: "Lightweight" },
      { position: "Featherweight" },
      { position: "Bantamweight" },
      { position: "Flyweight" },
         { position: "Freestyle Swimmer" },
      { position: "Backstroke Swimmer" },
      { position: "Breaststroke Swimmer" },
      { position: "Butterfly Swimmer" },
      { position: "Medley Swimmer" },
      { position: "Diver" }


];

export const sportsTeams = [

      {
        country: "Nigeria",
        teams: [
          "Enyimba International FC", "Kano Pillars FC", "Rangers International FC", "Shooting Stars SC", 
          "Lobi Stars FC", "Heartland FC", "Sunshine Stars FC", "Nasarawa United FC", "Plateau United FC", 
          "Akwa United FC", "MFM FC", "Abia Warriors FC", "Wikki Tourists FC", "Gombe United FC", "Katsina United FC", 
          "Remo Stars FC", "Bendel Insurance FC", "Warri Wolves FC", "Bayelsa United FC", "Dakkada FC", 
          "Jigawa Golden Stars FC", "Adamawa United FC", "Yobe Desert Stars FC", "El-Kanemi Warriors FC", 
          "Niger Tornadoes FC", "Kwara United FC", "FC Ifeanyi Ubah", "Rivers United FC", "Go Round FC", 
          "Ikorodu United FC", "ABS FC", "Gateway United FC", "Crown FC", "Ekiti United FC", "Osun United FC", 
          "Sokoto United FC", "Zamfara United FC", "Mighty Jets FC", "JUTH FC", "Kaduna United FC", "Ranchers Bees FC", 
          "Kogi United FC", "Kada City FC", "Doma United FC", "DSS FC", "Dalhatu United FC", "Babanawa FC", 
          "Bussdor United FC", "Calabar Rovers FC", "C.O.D United FC", "Bridge Boys FC", "First Bank FC", 
          "Fountain FC", "Galadima FC", "Giwa FC", "Ilesa Dynamos FC", "Inter Enugu FC", "Jasper United FC", 
          "Leventis United FC", "MFM FC", "My People FC", "NAF Rockets FC", "Nasarawa United FC", "Nembe City FC", 
          "NEPA Lagos FC", "New Nigeria Bank FC", "Niger Tornadoes FC", "NITEL Vasco Da Gama FC", "Oasis FC", 
          "Ocean Boys FC", "Owazi FC", "Prime FC", "Racca Rovers FC", "Real Sapphire FC", "Remo FC", "Rising Stars FC", 
          "Sharks FC", "Shooting Stars SC", "Sokoto United FC", "Sunshine Stars FC", "Taraba FC", "TEAP FC", 
          "Union Bank FC", "Warri Wolves FC", "Westside FC", "Wikki Tourists FC", "Zamfara United FC" 
        ]
      },
      {
        country: "Premier League",
        teams: [
          "Arsenal FC",
          "Chelsea FC",
          "Liverpool FC",
          "Manchester United FC",
          "Manchester City FC",
          "Tottenham Hotspur FC",
          "West Ham United FC",
          "Leicester City FC",
          "Everton FC",
          "Aston Villa FC",
          "Newcastle United FC",
          "Leeds United FC",
          "Wolverhampton Wanderers FC",
          "Southampton FC",
          "Crystal Palace FC",
          "Brighton & Hove Albion FC",
          "Burnley FC",
          "Watford FC",
          "Norwich City FC",
          "Brentford FC",
        ],
      },
      {
        country: "EFL Championship",
        teams: [
          "Fulham FC", "AFC Bournemouth", "Sheffield United FC", "West Bromwich Albion FC", "Nottingham Forest FC", 
          "Middlesbrough FC", "Blackburn Rovers FC", "Millwall FC", "Coventry City FC", "Queens Park Rangers FC", 
          "Stoke City", "Luton Town FC", "Preston North End FC", "Swansea City AFC", "Blackpool FC", "Cardiff City FC", 
          "Bristol City FC", "Hull City AFC", "Birmingham City FC", "Reading FC", "Peterborough United FC", 
          "Barnsley FC", "Derby County FC"
        ]
      },
      {
        country: "Nigeria Basketball League (BBL)",
        teams: [
          "Rivers Hoopers",
          "Kwara Falcons",
          "Lagos Islanders",
          "Kano Pillars Basketball Club",
          "Gombe Bulls",
          "Niger Potters",
          "Customs",
          "Police Baton",
          "Delta Force",
          "Oluyole Warriors",
          "Dodan Warriors",
          "Bauchi Nets",
          "Plateau Peaks",
          "Benue Braves",
          "Ebun Comets",
          "Union Bank",
          "Royal Hoopers",
          "Yelwa Hawks",
          "AHIP Giants",
          "Civil Defenders",
          "Mark Mentors",
          "Nigeria Immigration Service",
          "Nigeria Prisons Service",
          "Nigeria Air Force",
          "Nigeria Army",
          "Nigeria Navy",
          "FCT Rocks",
          "Osun Lions",
          "Ekiti Heat",
          "Ondo Raiders",
          "Anambra Flames",
          "Imo Raptors",
          "Abia Valiants",
          "Enugu Coal City Miners",
          "Bayelsa Patriots",
          "Cross River Raiders",
          "Akwa Ibom City Seals",
          "Edo Beads",
          "Kogi Confluence Kings",
          "Nasarawa Amazons",
          "Zamfara Shooters",
          "Sokoto Rima",
          "Kebbi Spiders",
          "Katsina Stars",
          "Jigawa Golden Stars",
          "Yobe Desert Warriors",
          "Borno Spiders",
          "Taraba Hurricanes",
          "Adamawa Lions",
        ],
      },
      {
        country: "British Basketball League (BBL)",
        teams: [
  "London Lions", "Leicester Riders", "Newcastle Eagles", "Glasgow Rocks", "Cheshire Phoenix", 
          "Sheffield Sharks", "Plymouth Raiders", "Worcester Wolves", "Manchester Giants", "Surrey Scorchers", 
          "Bristol Flyers"
        ]
      },
      {
        country: "Athletics",
        teams: [
      "Nigerian Army Athletics Team", "Nigerian Police Athletics Team", "Nigerian Customs Athletics Team", 
      "Nigerian Immigration Athletics Team", "Nigerian Prisons Athletics Team", "Delta State Athletics Association", 
      "Edo State Athletics Association", "Ondo State Athletics Association", "Ogun State Athletics Association", 
      "Lagos State Athletics Association", "Rivers State Athletics Association", "Bayelsa State Athletics Association", 
      "Cross River State Athletics Association", "Akwa Ibom State Athletics Association", 
      "Anambra State Athletics Association", "Enugu State Athletics Association",
      "Birchfield Harriers", "Blackheath & Bromley Harriers AC", "Sale Harriers Manchester", 
      "Shaftesbury Barnet Harriers", "Thames Valley Harriers", "Edinburgh AC", "Glasgow City AC", 
      "Cardiff AAC", "Swansea Harriers AC", "Belfast City Harriers"
        ]
      },
      {
        country: "Premiership Rugby",
        teams: [
  "Bath Rugby", "Bristol Bears", "Exeter Chiefs", "Gloucester Rugby", "Harlequins", "Leicester Tigers", 
          "London Irish", "Newcastle Falcons", "Northampton Saints", "Sale Sharks", "Saracens", "Wasps", 
          "Worcester Warriors"
        ]
      },


];

export const scoutSpecializations = [
  "Player Talent Identification",
  "Youth Development Scouting",
  "Professional Scouting",
  "Amateur Scouting",
  "Technical Scouting (Analytics)",
  "Goalkeeper Scouting",
  "Opposition Analysis",
  "International Scouting",
  "Regional Scouting",
  "Academy Scouting",
  "Women’s Football Scouting",
  "Fitness and Conditioning Assessment",
  "Psychological Profiling",
  "Transfer Market Scouting",
  "Tactical Scouting",
  "Event Scouting (Camps/Tournaments)",
  "Under-23 Scouting",
  "Injury Risk Assessment",
  "Positional Scouting (e.g., Forwards, Defenders)",
  "Freelance Scouting",
];

export const scoutingRegions = [
  "Local Area",
  "Regional",
  "National",
  "Continental",
  "International",
  "North America",
  "South America",
  "Europe",
  "Asia",
  "Africa",
  "Australia and Oceania",
  "Middle East",
  "Specific Country (e.g., USA, Brazil, Germany)",
  "Urban Areas",
  "Rural Areas",
  "Youth Leagues",
  "Professional Leagues",
  "Amateur Tournaments",
  "University/College Leagues",
  "High School Leagues"


]


export const ageGroups = [
  { label: "U5 (Under 5 years)", value: "u5" },
  { label: "U10 (Under 10 years)", value: "u10" },
  { label: "U15 (Under 15 years)", value: "u15" },
  { label: "U18 (Under 18 years)", value: "u18" },
  { label: "U21 (Under 21 years)", value: "u21" },
  { label: "Open Age (21+ years)", value: "open" },
];

export const leagueLevels = [
  "Youth League",
  "Amateur League",
  "Semi-Professional League",
  "Professional League",
  "National League",
  "Regional League",
  "International League",
  "Championship League",
  "Premier League",
  "Division 1",
  "Division 2",
  "Friendly Matches",
];

export const sportsRecruitmentAreas = [
  "Player Scouting",
  "Coach Recruitment",
  "Youth Development Programs",
  "Medical Staff Hiring",
  "Performance Analysts",
  "Strength and Conditioning Coaches",
  "Event Management Team",
  "Marketing and Sponsorships",
  "Fan Engagement Specialists",
  "Volunteer Recruitment"
];

export const playerTypesByPosition = [
  "Goalkeeper",
  "Defender",
  "Midfielder",
  "Forward",
   "Playmaker",
  "Winger",
  "Striker",
  "Box-to-Box Midfielder",
  "Sweeper",
  "Ball-Winning Midfielder",
  "Target Man",
  "Deep-Lying Playmaker",
  "Captain",
  "Vice-Captain",
  "Substitute",
  "Reserve",
  "Youth Player"
];


