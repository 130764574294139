import { Avatar, Box, Button, LinearProgress, Typography } from "@mui/material";
import { getOtherUserProfile } from "api";
import BackdropLoader from "components/loaders/Backdrop";
// import Icons from "constants/Icons";
import successTone from "../../../assets/tone/success-tone.mp3";
import images from "constants/images";
import AccessLayout from "layouts/AccessLayout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PATHS } from "Routes/routes.path";
import coverImage from "../../../assets/imgs/coverr.png";
import { BsPeopleFill } from "react-icons/bs";
import { useCreateConnect } from "pages/network/hooks/useCreateConnect";
import { MdAccessTime } from "react-icons/md";
import { useToggleFollowUser } from "pages/network/hooks/useFollow";
import ProfileDisplay from "./ProfileDisplay";
import { OtherUsersPayload } from "api/types";
import { useLazyGetPostsByUserQuery } from "pages/feeds/api";
import FeedItem from "pages/feeds/Post";

const OtherUserProfile = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<OtherUsersPayload>();
  const [loading, setLoading] = useState<boolean>(false);
  const [triggerPosts, { data: posts }] = useLazyGetPostsByUserQuery();

  const handleOtherProfile = async () => {
    setLoading(true);
    await getOtherUserProfile(id ?? "")
      .then((res) => {
        setLoading(false);
        const response = res?.data;
        setData(response);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    handleOtherProfile();
    triggerPosts({ email: id || "" });
  }, [id]);

  const profile = data?.data;
  const { firstName, lastName, sportType, profileType, country, email } =
    profile?.user || {};
  const {
    currentClub,
    preferredPosition,
    dateOfBirth,
    height,
    weight,
    biography,

    preferredClub,
    preferredFoot,

    // SCOUTS
    yearsOfExperience,
    scoutingExperienceYears,
    notableTalents,
    areasOfSpecialization,
    affiliatedOrganizations,
    scoutingRegion,
    certifications,
    preferredAttributes,
    regionsOfInterest,
    sports,

    position,
    ageGroup,
    scoutingHistory,

    socialMediaLinks,

    // FANBASE
    favoriteSports,
    favoriteAthletes,
    notificationPreferences,
    interactions,
    purchasedItems,
    competitionLevel,

    // CLUB
    clubName,
    contactEmail,
    contactPersonName,
    contactPhone,
    website,
    socialLinks,
    recruitmentAreas,
    playerType,
    teamLogoUrl,
    players,
    clubAchievements,
    clubVacancies,
  } = profile?.profile || {};

  const personalInformation = {
    country,
    height,
    weight,
    scoutingExperienceYears,
    notableTalents,
    areasOfSpecialization,
    favoriteSports,
    favoriteAthletes,
    notificationPreferences,
    clubName,
    contactEmail,
    contactPersonName,
    contactPhone,
  };

  const professionalInfo = {
    currentClub,
    preferredPosition,
    yearsOfExperience,
    preferredFoot,
    affiliatedOrganizations,
    scoutingRegion,
    certifications,
    preferredAttributes,
    regionsOfInterest,
    sports,
    interactions,
    purchasedItems,
    website,
    socialLinks,
    recruitmentAreas,
    playerType,
    teamLogoUrl,
    players,
  };

  const otherInformations = {
    preferredClub,
    position,
    ageGroup,
    scoutingHistory,
    socialMediaLinks,
    competitionLevel,
    clubAchievements,
    clubVacancies,
  };

  const { followers, connections } = profile || {};

  const playSuccessTone = () => {
    const audio = new Audio(successTone); // Replace with the path to your audio file
    audio.play();
  };

  const {
    createConnectionHandler,
    loadingButtonId: connectionLoader,
  } = useCreateConnect();

  const [updatedstatus, setUpdatedStatus] = useState<boolean>(false);

  const handleConnect = async (email: string, id: string) => {
    await createConnectionHandler(email, id);

    setUpdatedStatus(true);
    playSuccessTone();
  };

  const { createToggleFollowHandler } = useToggleFollowUser();

  const handleToggleFollow = async (email: string, id: string) => {
    await createToggleFollowHandler(email, id);

    setUpdatedStatus(true);
    playSuccessTone();
  };
  return (
    <>
      {loading && <BackdropLoader />}
      {!loading && (
        <AccessLayout path={PATHS.NETWORK}>
          <Box
            sx={{
              width: { lg: "70%", md: "75%", sm: "85%", xs: "100%" },
              margin: "0 auto",
              mt: 3,
            }}
          >
            <Box
              sx={{
                height: "150px",
                background: `url('${coverImage}')`,
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                pr: 6,
              }}
            >
              <div>
                <Typography
                  sx={{
                    fontFamily: "butler",
                    color: "#fff",
                    fontSize: {
                      lg: "40px",
                      md: "40px",
                      sm: "35px",
                      xs: "30px",
                    },
                    textAlign: "right",
                  }}
                >
                  {profileType}
                </Typography>
                <Typography
                  sx={{
                    color: "#ccc",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    textAlign: "right",
                    mt: -2,
                  }}
                >
                  {sportType}
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                // borderRadius: "12px",
                px: 3,
                boxSizing: "border-box",
                display: "flex",
                alignItems: { xs: "left", md: "center" },
                justifyContent: "space-between",
                rowGap: 2,
                bgcolor: "#fff",
                boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                pb: 4,
                flexDirection: { xs: "column", sm: "column", md: "row" },
              }}
            >
              <div className="flex flex-col ">
                <Avatar
                  src={
                    profile?.asset?.profilePictureUrl || images.defaultAvater
                  }
                  sx={{
                    width: "120px",
                    height: "120px",
                    mt: -11,
                    border: "3px solid #fff",
                  }}
                />
                <div className="mt-3">
                  <p className="font-[600] text-[25px]">
                    {firstName} {lastName}
                  </p>
                  <p className="text-gray-400 text-[14px]">
                    {profileType} - {sportType}
                  </p>
                </div>
                <div className="mt-3 rounded-[4px] flex items-center justify-center w-fit  gap-2 ">
                  {country && (
                    <p className="text-[14px]">
                      {country}
                      <span style={{ marginLeft: "9px" }}>|</span>{" "}
                    </p>
                  )}
                  <p className="text-[14px]">{connections} Connections </p> .{" "}
                  <p className="text-[14px]">{followers} Followers</p>
                </div>
              </div>
              <div>
                {profileType === "TEAM" ? (
                  <Button
                    variant="outlined"
                    onClick={() => handleToggleFollow(email || "", "1")}
                    disabled={connectionLoader === "1"}
                    sx={{
                      width: "110px",
                      fontSize: "12px",
                      height: "37px",
                    }}
                  >
                    {updatedstatus ? "Unfollow" : "Follow"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleConnect(email || "", "1")}
                    disabled={connectionLoader === "1" || updatedstatus}
                    startIcon={
                      updatedstatus ? (
                        <MdAccessTime style={{ fontSize: "14px" }} />
                      ) : (
                        <BsPeopleFill style={{ fontSize: "14px" }} />
                      )
                    }
                    className="border border-primary text-primary text-xs py-1 px-4 rounded-2xl"
                    sx={{
                      width: "110px",
                      bgcolor: "primary.main",
                      color: "#fff",
                      px: 2,
                      fontSize: "12px",
                      height: "37px",
                      boxShadow: "none",
                    }}
                  >
                    {connectionLoader === "1" ? (
                      <LinearProgress sx={{ width: "40px", height: "2px" }} />
                    ) : updatedstatus ? (
                      "Pending"
                    ) : (
                      "Connect"
                    )}
                  </Button>
                )}
              </div>
            </Box>

            <Box
              sx={{
                bgcolor: "#fff",
                borderRadius: "12px",
                px: 3,
                boxSizing: "border-box",
                display: "flex",
                boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                justifyContent: "space-between",
                mt: 3,
                columnGap: 4,
                rowGap: 2,
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <div className="pt-5 flex-1 pb-6  md:w-[35%]">
                {/* Personnal Info */}
                <p className="text-black font-[500]">Personal information</p>
                <hr className="my-3" />
                {dateOfBirth && (
                  <div className="flex  space-x-3 mt-2">
                    <p className="text-[14px] whitespace-nowrap">Age:</p>
                    <p className="text-black font-[500] text-[14px]">
                      {getAge(dateOfBirth)}
                    </p>
                  </div>
                )}
                <ProfileDisplay profileDetails={personalInformation} />

                {/* Professional Information */}
                <div className="mt-8">
                  <p className="text-black font-[500]">
                    Professional information
                  </p>
                  <hr className="my-3" />

                  <ProfileDisplay profileDetails={professionalInfo} />
                </div>

                {/* Dream Goal / Ambition */}
                <div className="mt-8">
                  <p className="text-black font-[500]">Other Informations</p>
                  <hr className="my-3" />
                  <ProfileDisplay profileDetails={otherInformations} />

                  {biography && (
                    <div className="mt-4 bg-blue-50 p-4 rounded-md">
                      <p>Bio</p>
                      <p className="text-[14px] text-black">{biography}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="md:w-[65%] md:border-l-[1px] py-4 md:pl-6">
                <Typography
                  variant="h$20"
                  fontWeight="medium"
                  color="black"
                  pb={2}
                >
                  All Posts
                </Typography>
                <div>
                  {posts?.data && posts?.data.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          height: "200px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{fontWeight:500, textAlign:'center'}}>
                          {firstName} hasn’t posted yet

                        </Typography>
                        <Typography sx={{fontSize:'12px', textAlign:'center'}}>
                Recent posts {firstName}  shares will be displayed here.

                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      {posts?.data && posts?.data.map((post) => (
                        <FeedItem key={post.id} post={post} compact />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </Box>
          </Box>
        </AccessLayout>
      )}
    </>
  );
};

export default OtherUserProfile;

const getAge = (dob: string): number => {
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();

  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};
