import PersonalInformation from "./PersonalInformation";
import ClubInformation from "./ClubInformation";
import MediaUpload from "./MediaUpload";
import { SectionKeys } from "../useSectionHook";
import { useState } from "react";

interface Props {
  currentSectionKey: SectionKeys<"ATHLETES">;
  onNext: () => void;
  onPrevious: () => void;
  onComplete: () => void;
}

const Athletes = (props: Props) => {
  const { currentSectionKey, onNext, onPrevious, onComplete } = props;

  const [personalInfo, setPersonalInfo] = useState(null);
  const [clubInfo, setClublInfo] = useState(null);

  const handlePersonalData = (data: any) => {
    setPersonalInfo(data);
  };
  const handleClubData = (data: any) => {
    setClublInfo(data);
  };



  return (
    <>
      {/* Form 1 */}
      {currentSectionKey == "personalInfo" && (
        <PersonalInformation
          onNext={onNext}
          onSubmitFormData={handlePersonalData}
        />
      )}
      {/* Form 2 */}
      {currentSectionKey == "professionalInfo" && (
        <ClubInformation
          onNext={onNext}
          onPrevious={onPrevious}
          onSubmitFormData={handleClubData}
        />
      )}
      {/* Form 3 */}
      {currentSectionKey == "mediaUpload" && (
        <MediaUpload onComplete={onComplete} onPrevious={onPrevious} clubInformation={clubInfo} personalInformation={personalInfo} />
      )}
    </>
  );
};

export default Athletes;
