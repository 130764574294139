import AccessLayout from "layouts/AccessLayout";
import { PATHS } from "Routes/routes.path";
import FeedLayout from "layouts/FeedLayout";
import { Box, Divider, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLazyGetPostsQuery } from "./api";
import useAuthDetails from "pages/auth/useAuthDetails";
import FeedItem from "./Post";
import FeedPost from "./FeedPost";
import CompleteProfileModal from "components/modals/CompleteProfileModal";
import { TouchEvent, useEffect, useRef, useState } from "react";
import throttle from "lodash.throttle";
import { Link } from "react-router-dom";
import { HeaderHeight } from "constants/layers";

const sentinelStyle = { height: 1 };
const thresholdBeforeLoadingNextPage = 250; // px

const FetchLoader = () => {
  return (
    <div className="flex justify-center items-center space-x-2 mb-4">
      <div className="w-3 h-3 bg-gv-greyMinus2_DCE1EC rounded-full animate-bounce"></div>
      <div className="w-3 h-3 bg-gv-greyMinus2_DCE1EC rounded-full animate-bounce delay-150"></div>
      <div className="w-3 h-3 bg-gv-greyMinus2_DCE1EC rounded-full animate-bounce delay-300"></div>
    </div>
  );
};

const Feed = () => {
  const hasNextRef = useRef(true);
  const [trigger, result] = useLazyGetPostsQuery();
  const currentPageRef = useRef(result.data?.pagination.currentPage ?? 1);
  const { isProfileCompleted } = useAuthDetails();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const startYRef = useRef<number | null>(null);

  const sentinelRef = useRef<HTMLDivElement | null>(null);
  const locked = useRef(false);
  const sentinelDistanceFromWindowBottom = useRef(0);

  const isInView = () => {
    if (!sentinelRef.current) {
      return false;
    }
    const { top } = sentinelRef.current.getBoundingClientRect();
    const distanceFromWindowBottom = top - (window.innerHeight || 0);
    sentinelDistanceFromWindowBottom.current = distanceFromWindowBottom;

    return distanceFromWindowBottom < thresholdBeforeLoadingNextPage;
  };

  const fetchData = async () => {
    const currentPage = currentPageRef.current;

    try {
      const { data } = await trigger({ page: currentPageRef.current, size: 5 });

      if (data) {
        hasNextRef.current = currentPage != data.pagination.totalPages;
        currentPageRef.current += 1;
      }

      setTimeout(() => setIsRefreshing(false), 1000);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (result.isUninitialized) {
      currentPageRef.current = 1;
    }
  }, [result.isUninitialized]);

  useEffect(() => {
    if (result.data?.stale) {
      currentPageRef.current = 1;
      fetchData();
    }
  }, [result.data?.stale]);

  useEffect(() => {
    const scrollListener = throttle(async () => {
      if (result.error) {
        return;
      }

      if (locked.current) {
        return;
      }

      if (result.isFetching) {
        return;
      }

      if (!hasNextRef.current) {
        return;
      }

      if (!isInView()) {
        return;
      }

      locked.current = true;
      await fetchData();

      // Let the stack clear before unlocking.
      setTimeout(() => {
        locked.current = false;
      }, 0);
    }, 100);

    scrollListener();
    scrollRef.current?.addEventListener("scroll", scrollListener);

    return () => {
      scrollRef.current?.removeEventListener("scroll", scrollListener);
    };
  }, []);

  const handleTouchStart = (event: TouchEvent) => {
    if (locked.current) {
      return;
    }

    if (isRefreshing) {
      return;
    }

    // Only start tracking if the scrollbar is at the top of the screen
    if (event.currentTarget.scrollTop != 0) {
      return;
    }

    startYRef.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (startYRef.current == null) {
      return;
    }

    if (locked.current) {
      return;
    }

    if (isRefreshing) {
      return;
    }

    const currentY = event.touches[0].clientY;

    // Detect downward pull
    if (currentY - startYRef.current > 100) {
      currentPageRef.current = 1;
      setIsRefreshing(true);
      locked.current = true;
      fetchData();

      setTimeout(() => {
        locked.current = false;
      }, 1000);
    }
  };

  const handleTouchEnd = () => {
    startYRef.current = null;
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener("touchstart", (e) =>
        handleTouchStart(e as unknown as TouchEvent)
      );
      scrollRef.current.addEventListener("touchmove", (e) =>
        handleTouchMove(e as unknown as TouchEvent)
      );
      scrollRef.current.addEventListener("touchend", handleTouchEnd);
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("touchstart", (e) =>
          handleTouchStart(e as unknown as TouchEvent)
        );
        scrollRef.current.removeEventListener("touchmove", (e) =>
          handleTouchMove(e as unknown as TouchEvent)
        );
        scrollRef.current.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <AccessLayout path={PATHS.FEED}>
        <FeedLayout ref={scrollRef}>
          <Helmet>
            <title>Feed | Golvia</title>
          </Helmet>
          {isRefreshing && (
            <div
              className={`absolute top-[calc(${HeaderHeight.mobile} + 20px)] -left-full -right-full`}
            >
              <FetchLoader />
            </div>
          )}

          <Box mt={isRefreshing ? 3 : "initial"}>
            {isMobile && (
              <div className="flex items-center gap-x-2 my-2">
                <Divider sx={{ flex: 1 }} />
                <Link to={PATHS.LIVESCORE}>
                  <p className="text-primary text-[14px] pr-1 font-[500]">Browse Livescores</p>
                </Link>
              </div>
            )}

            <div
              className="bg-white rounded-2xl card-border"
              style={{ border: "0.5px solid #dfdeda " }}
            >
              <FeedPost />
            </div>

            {/* Profile Level Banner */}
            {!isProfileCompleted && (
              <Stack
                justifyContent="space-between"
                width="100%"
                padding={2}
                className="mt-2 bg-tertiary rounded-2xl"
              >
                <div>
                  <h3 className="text-[14px]">
                    Profile Level <span className="text-primary font-medium">40% of 100</span>
                  </h3>
                  <h4 className="text-[16px] font-normal">
                    Complete your profile to start using Golvia app
                  </h4>
                </div>
                <div>
                  <button
                    className="rounded-lg border bg-primary text-white px-4  mt-2 py-2 text-sm font-medium"
                    onClick={handleOpen}
                  >
                    Get Started
                  </button>
                </div>
              </Stack>
            )}

            {/* Feed Content */}
            <div>
              {result.currentData?.data.map((post) => <FeedItem key={post.id} post={post} />)}

              <Box
                ref={sentinelRef}
                style={sentinelStyle}
                mb={!!result.data && result.isFetching ? 2 : 0}
              />

              {!!result.currentData && result.isFetching && <FetchLoader />}

              {!result.currentData && (
                <Stack spacing={2} className="mt-2">
                  <Stack direction="row" spacing={2}>
                    <Skeleton variant="circular" width={50} height={50} />
                    <Stack>
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={200} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={150} />
                    </Stack>
                  </Stack>

                  <Skeleton variant="rounded" width="100%" height={400} />
                  <Stack width="100%" direction="row" spacing={2}>
                    <Skeleton variant="circular" width={30} height={30} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} height={30} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} height={30} />
                  </Stack>
                </Stack>
              )}
            </div>
          </Box>
        </FeedLayout>
      </AccessLayout>

      <CompleteProfileModal handleClose={handleClose} open={open} />
    </>
  );
};

export default Feed;
