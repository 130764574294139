import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLikePostMutation } from "../api";
import { useState } from "react";
import { ReactComponent as OutlinedLikeIcon } from "assets/icons/outlined-like-icon.svg";
import { ReactComponent as CommentIcon } from "assets/icons/comment-icon.svg";

function ActionButton({
  icon: Icon,
  text,
  onClick,
  iconColor,
  className,
}: {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconColor?: string;
  text: string;
  onClick: (e: MouseEvent) => void;
  className?: string;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack
      direction="row"
      py={!isMobile ? 0.5 : 1}
      px={1.5}
      spacing={1}
      alignItems="center"
      className={`bg-quaternary rounded-3xl cursor-pointer ${className}`}
      onClick={(e) => onClick(e as unknown as MouseEvent)}
    >
      <Icon height="20px" width="20px" color={iconColor} />
      {!isMobile && <Typography variant="p$14">{text}</Typography>}
    </Stack>
  );
}

export default function PostActions({
  id,
  openComments,
  isLiked: _isLiked,
}: {
  id: number;
  isLiked: boolean;
  openComments?: (e: MouseEvent) => void;
}) {
  const [likePost] = useLikePostMutation();
  const [isLiked, setIsLiked] = useState(_isLiked);

  const handleLikePost = () => {
    setIsLiked((prev) => !prev);
    likePost(id);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <ActionButton
        text="Like"
        icon={OutlinedLikeIcon}
        onClick={handleLikePost}
        className={`${isLiked && "!bg-gv-blue_1D69D8 text-white"}`}
      />
      {openComments && <ActionButton text="Comment" icon={CommentIcon} onClick={openComments} />}
    </Stack>
  );
}
