import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  // AccordionActions,
  AccordionDetails,
  AccordionSummary,
  AppBar,
} from "@mui/material";
import "./home.css";
import logo from "./img/logo.svg";
import { useState, useEffect } from "react";
import img1 from "./img/2.webp";
import icons from "./constant/icons";
import { CiCircleChevRight } from "react-icons/ci";
// import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { IoLogoTiktok } from "react-icons/io5";

import { IoIosArrowRoundDown } from "react-icons/io";

import "aos/dist/aos.css";
import Aos from "aos";
import { HashLink } from "react-router-hash-link";

import { useAppSelector } from "store/hooks";
import { selectToken } from "pages/auth/slice";

import { Navigate, useNavigate } from "react-router-dom";
import { PATHS } from "Routes/routes.path";
import { Link } from "react-router-dom";

function Home() {
  const [animate, setAnimate] = useState(false);

  const mobileTheme = useTheme();

  const isMobile = useMediaQuery(mobileTheme.breakpoints.down("md"));

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const token = useAppSelector(selectToken);
  const user = token || window.localStorage.getItem("authToken");

  const navigate = useNavigate();

  //   useEffect(()=>{
  // if(user){
  //   navigate(PATHS.FEED)
  // }
  //   },[token, user])

  const scrollWithOffset = (el: HTMLElement): void => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; // Adjust offset value as needed
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const [color, setColor] = useState<boolean>(false);

  const changeColor = (): void => {
    if (window.scrollY >= 60) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <>
      {user ? (
        <Navigate to={PATHS.FEED} />
      ) : (
        <>
          <AppBar
            className={color ? " appbar_bg" : "appbar"}
            sx={{ bgcolor: "transparent", boxShadow: "none" }}
          >
            <Box
              sx={{
                height: "100px",
                // border: "1px solid",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: {
                  xl: "75%",
                  lg: "85%",
                  md: "85%",
                  sm: "90%",
                  xs: "90%",
                },
                margin: "0 auto",
              }}
            >
              {/* Logo Component */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1.7,

                  justifyContent: isMobile ? "center" : "initial",
                }}
              >
                <img src={logo} alt="Golvia_logo" width={40} />

                <Box
                  sx={{ overflowX: "hidden", height: "40px" }}
                  className="hide_scrollbar"
                >
                  <Typography
                    className={`text-container ${animate ? "animate" : ""}`}
                    sx={{
                      fontSize: "30px",
                      fontWeight: 500,
                      height: "100%",
                      color: "#fff",
                    }}
                  >
                    Golvia
                  </Typography>
                </Box>
              </Box>

              {/* Nav Component */}
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 5 }}>
                {!isMobile && (
                  <>
                    <HashLink
                      to="#our_mission"
                      className="cursor-pointer hover:font-[500]"
                      smooth={true}
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      <Typography
                        sx={{
                          cursor: "pointer",
                          transition: "0.2s all linear",
                          "&:hover": {
                            fontWeight: 600,
                          },
                        }}
                      >
                        Our Mission
                      </Typography>
                    </HashLink>
                    <HashLink
                      to="#get_started"
                      className="cursor-pointer hover:font-[500]"
                      smooth={true}
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      <Typography
                        sx={{
                          cursor: "pointer",
                          transition: "0.2s all linear",
                          "&:hover": {
                            fontWeight: 600,
                          },
                        }}
                      >
                        FAQs
                      </Typography>
                    </HashLink>
                    <Link to={PATHS.SIGNUP}>
                      <Button
                        sx={{
                          fontSize: "12px",
                          bgcolor: "#fff",
                          color: "#000",
                          textTransform: "initial",
                          borderRadius: "8px",
                          height: "50px",
                          width: "180px",
                        }}
                        endIcon={
                          <CiCircleChevRight style={{ color: "#000" }} />
                        }
                      >
                        Create an account
                      </Button>
                    </Link>
                  </>
                )}

                <Link to={PATHS.LOGIN}>
                  <Button
                    sx={{
                      fontSize: "12px",
                      bgcolor: "#ffffff1f",
                      color: "#fff",
                      textTransform: "initial",
                      borderRadius: "8px",
                      height: "50px",
                      width: "100px",
                      border: "1px solid #ffffff1f",
                    }}
                  >
                    Login
                  </Button>
                </Link>
              </Box>
            </Box>
          </AppBar>
          <Box
            sx={{
              height: { lg: "90vh", md: "90vh", sm: "90vh", xs: "100vh" },
              background: "#1D69D8",
              backgroundImage: `url(${icons.hero_bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflow: "hidden",
              pt: { lg: 17, md: 17, sm: 10, xs: 8 },
            }}
          >
            <Box
              sx={{
                width: {
                  xl: "75%",
                  lg: "85%",
                  md: "85%",
                  sm: "90%",
                  xs: "90%",
                },
                margin: "0 auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 3,
                  justifyContent: isMobile ? "center" : "space-between",

                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                }}
              >
                <Box
                  sx={{
                    width: { lg: "60%", md: "60%", sm: "100%", xs: "100%" },
                    my: 5,
                    ...(isMobile && {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }),
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#fff",
                      fontSize: {
                        lg: "48px",
                        md: "48px",
                        sm: "38px",
                        xs: "35px",
                      },
                      fontWeight: 500,
                      mt: 4,
                      textAlign: isMobile ? "center" : "left",
                    }}
                    className="animate four"
                  >
                    The one social network for <span>A</span>
                    <span>t</span>
                    <span>h</span>
                    <span>l</span>
                    <span>e</span>
                    <span>t</span>
                    <span>e</span>
                    <span>s,</span> <span>S</span>
                    <span>c</span>
                    <span>o</span>
                    <span>u</span>
                    <span>t</span> , <span>C</span>
                    <span>l</span>
                    <span>u</span>
                    <span>b</span>
                    <span>s</span> and <span>F</span>
                    <span>a</span>
                    <span>n</span>
                    <span>s</span> to connect.
                  </Typography>
                  <Link to={PATHS.SIGNUP}>
                    <Button
                      sx={{
                        mt: 4,
                        fontSize: "12px",
                        bgcolor: "#fff",
                        color: "#000",
                        textTransform: "initial",
                        borderRadius: "8px",
                        height: "50px",
                        width: "250px",
                      }}
                      endIcon={<CiCircleChevRight style={{ color: "#000" }} />}
                    >
                      Create an account
                    </Button>
                  </Link>
                </Box>
                <Box
                  sx={{ width: { lg: "40%", md: "40%", sm: "70%", xs: "80%" } }}
                  data-aos="fade-left"
                >
                  <Box
                    sx={{
                      background: `url(${icons.board1})`,
                      height: {
                        lg: "600px",
                        md: "600px",
                        sm: "500px",
                        xs: "500px",
                      },
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <Box
                      sx={{
                        margin: "0 auto",
                        height: "100%",
                        mb: -20,
                        width: "100%",
                        p: 1,
                        boxSizing: "border-box",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: { lg: 20, md: 20, sm: 15, xs: 10 },
                          mx: { lg: 0, md: 0, sm: -3, xs: -5 },
                        }}
                      >
                        <Box sx={{ ml: 0 }}>
                          <img src={icons.head1} width={50} className="image" />
                        </Box>
                        <Box sx={{ mt: 4 }}>
                          <img src={icons.head3} width={50} className="image" />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 18,
                        }}
                      >
                        <Box sx={{ ml: 0 }}>
                          <img src={icons.head2} width={50} className="image" />
                        </Box>
                        <Box sx={{ mt: 4 }}>
                          <img src={icons.head4} width={55} className="image" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            id="our_mission"
            sx={{
              width: { xl: "75%", lg: "85%", md: "85%", sm: "90%", xs: "95%" },
              margin: "0 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 10,
              pb: 8,
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column-reverse",
                xs: "column-reverse",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  lg: "50%",
                  md: "50%",
                  sm: "80%",
                  xs: "90%",
                  ...(isMobile && {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }),
                },
              }}
              data-aos="fade-up"
              // align={isMobile && "center"}
            >
              <img src={icons.board2} width={"70%"} />
            </Box>
            <Box
              sx={{
                width: { lg: "40%", md: "40%", sm: "80%", xs: "90%" },
                mb: { lg: 0, md: 0, sm: 4, xs: 4 },
                ...(isMobile && {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }),
              }}
              data-aos="fade-right"
            >
              <Typography
                variant="h2"
                sx={{ fontSize: "30px", fontWeight: 600, fontFamily: "outfit" }}
              >
                Our Mission
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: { lg: "18px", md: "18px", sm: "16px", xs: "16px" },
                  textAlign: isMobile ? "center" : "left",
                }}
              >
                Our mission is to be The one social media platform for athletes,
                scouts, clubs, and fans to connect
              </Typography>
              <Link to={PATHS.SIGNUP}>
                <Button
                  variant="contained"
                  sx={{
                    mt: 6,
                    textTransform: "initial",
                    borderRadius: "8px",
                    height: "40px",
                    px: 5,
                  }}
                  endIcon={<CiCircleChevRight style={{ color: "#fff" }} />}
                >
                  Create an account
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#EAF1FD",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                fontSize: { lg: "30px", md: "30px", sm: "27px", xs: "23px" },
                fontWeight: 600,
                mt: 7,
                mb: 3,
              }}
              data-aos="zoom-out"
            >
              Unforgettable Sports
              <br /> Memories with Gol-Via
            </Typography>
            <img src={img1} width={isMobile ? "80%" : "50%"} />
          </Box>
          <Box
            sx={{
              bgcolor: "primary.main",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: {
                  xl: "75%",
                  lg: "85%",
                  md: "85%",
                  sm: "90%",
                  xs: "90%",
                },
                margin: "0 auto",
                display: "flex",
                pt: 10,
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "column-reverse",
                  xs: "column-reverse",
                },
              }}
            >
              <Box
                sx={{
                  width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" },
                  mb: -12,
                  ...(isMobile && {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }),
                }}
                data-aos="fade-down"
                // align={isMobile && "center"}
              >
                <img src={icons.board3} width={"60%"} />
              </Box>
              <Box
                // align={isMobile && "center"}
                data-aos="fade-up"
                sx={{
                  width: { lg: "40%", md: "40%", sm: "100%", xs: "100%" },
                  mb: { lg: 0, md: 0, sm: 6, xs: 6 },

                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  justifyContent: "center",
                  ...(isMobile && {
                    alignItems: "center",
                  }),
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ color: "#fff", fontWeight: 600, fontSize: "30px",                  textAlign:isMobile ? "center": "left" }}
                >
                  Get in the Game
                  <br /> with Gol-Via
                </Typography>

                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 400,
                    mt: 3,
                    fontSize: {
                      lg: "18px",
                      md: "18px",
                      sm: "16px",
                      xs: "16px",
                      
                    },
                    textAlign:isMobile ? "center": "left"
                  }}
                >
                  From one sport to all your favorite sports, we've got you
                  covered. Stay up-to-date on the latest news and highlights.
                </Typography>

                <Box sx={{ mt: 3 }} id="get_started">
                  <Link to={PATHS.SIGNUP}>
                    <Button
                      sx={{
                        bgcolor: "#fff",
                        textTransform: "initial",
                        width: "200px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                      endIcon={<CiCircleChevRight style={{ color: "#ff" }} />}
                    >
                      Create an account
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* WAITING LIST FORM SECTION ==================================
=================================================== */}

          {/* FAQS=========================
        ================================ */}

          <Box sx={{ bgcolor: "#fff", py: 5 }}>
            <Box
              sx={{
                width: {
                  xl: "50%",
                  lg: "55%",
                  md: "60%",
                  sm: "90%",
                  xs: "90%",
                },
                margin: "0 auto",
                mt: 4,
              }}
            >
              <Typography
                sx={{ fontWeight: 600, textAlign: "center", fontSize: "30px" }}
              >
                FAQs
              </Typography>

              <Box>
                {faqs.map((faq, index) => (
                  <Accordion
                    sx={{
                      border: "0.5px solid #B4B4B4",
                      mb: 2,
                      borderRadius: "10px",
                      boxShadow: "none",
                      "&::before": { height: "0px" },
                    }}
                    key={index}
                  >
                    <AccordionSummary
                      sx={{ borderRadius: "10px" }}
                      expandIcon={<IoIosArrowRoundDown />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography> {faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography> {faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Box>
          </Box>

          {/* FOOTER =======================================================
==================================================================== */}

          <Box
            sx={{
              bgcolor: "#EAF1FD",
              height: { lg: "200px", md: "200px", sm: "auto", xs: "auto" },
            }}
          >
            <Box
              sx={{
                width: {
                  xl: "75%",
                  lg: "85%",
                  md: "85%",
                  sm: "90%",
                  xs: "95%",
                },
                margin: "0 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
                rowGap: 3,
                py: 4,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                <img src={icons.logo} />
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600, color: "#000" }}
                >
                  Golvia
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                  Connecting athletes to opportunities worldwide
                </Typography>
                <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                  All right reserved (c) 2024
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
               <a href="https://www.tiktok.com/@golvia.inc?_t=8r2Vggezxqf&_r=1" target="_blank">
                <IconButton
                  sx={{ bgcolor: "#000", width: "45px", height: "45px" , "&:hover":{bgcolor:'primary.main'}}}
                >
                  <IoLogoTiktok style={{ color: "#fff", fontSize: "16px" }} />
                </IconButton>
                </a>
                <a href="https://x.com/golviainc?s=21&t=2nIXkIYuYLkXKsBnW3vkuA" target="_blank">
          
                <IconButton
                  sx={{ bgcolor: "#000", width: "45px", height: "45px" , "&:hover":{bgcolor:'primary.main'}}}
                >
                  <FaXTwitter style={{ color: "#fff", fontSize: "16px" }} />
                </IconButton>
        
                </a>
                      < a href="https://www.instagram.com/golvia.in?igsh=MTNsMmdqdHEzem9qdg%3D%3D&utm_source=qr" target="_blank">
                <IconButton
                  sx={{ bgcolor: "#000", width: "45px", height: "45px" , "&:hover":{bgcolor:'primary.main'}}}
                >
                  <FaInstagram style={{ color: "#fff", fontSize: "16px" }} />
                </IconButton>
                        </a>
                        <a href="https://www.linkedin.com/company/golvia-inc/posts/?feedView=all" target="_blank">
                <IconButton
                  sx={{ bgcolor: "#000", width: "45px", height: "45px" , transition:'0.2s all linear', "&:hover":{bgcolor:'primary.main'}}}
                >
                  <FaLinkedinIn style={{ color: "#fff", fontSize: "16px" }} />
                </IconButton>
                </a>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default Home;

const faqs = [
  {
    question: "What is Golvia?",
    answer:
      "Golvia is a new social media platform specifically designed for athletes, coaches, academies, scouts, agents, sports enthusiasts, and fans. It aims to create a focused and supportive community where users can connect, showcase their talents, and collaborate without the distractions often found on traditional social media platforms. 'Gol-Via' means 'Goal Through' and signifies 'Score Your Dream Goal.'",
  },
  {
    question: "How is Golvia different from other social media platforms?",
    answer:
      "Unlike existing platforms that can be noisy and overwhelming, Golvia offers a tailored experience for the sports community. It prioritizes athlete development, networking opportunities, and meaningful interactions. Users can easily connect with other athletes, coaches, and sports organizations, fostering a community that supports growth and collaboration.",
  },
  {
    question: "Who can join Golvia?",
    answer:
      "Golvia is open to anyone involved in the sports community, including athletes, coaches, scouts, agents, sports businesses, and fans. Whether you're a professional athlete or just starting your journey in sports, Golvia provides a space for everyone.",
  },
  {
    question: "What features does Golvia offer?",
    answer:
      "Golvia includes features such as: Profile Creation (Showcase your skills, achievements, and sports journey), Networking Opportunities (Connect with coaches, scouts, and other athletes), Talent Showcasing (Share videos, photos, and updates about your sports activities), Collaboration Tools (Engage with fellow athletes and coaches for training, advice, and partnership opportunities), and a Marketplace (Connect with sports businesses for gear, training, and other resources).",
  },
  {
    question: "How do I create an account on Golvia?",
    answer:
      "To create an account, simply visit our website at www.gol-via.com or download the Golvia app from your app store. Follow the prompts to set up your profile and start connecting with the sports community.",
  },
  {
    question: "Is Golvia free to use?",
    answer:
      "Yes, Golvia offers a free membership option that provides access to basic features. We may also introduce premium features in the future for enhanced user experiences.",
  },
  {
    question: "How does Golvia ensure a safe and positive environment?",
    answer:
      "Golvia is committed to maintaining a safe and respectful environment. We implement community guidelines, moderation practices, and user reporting tools to address inappropriate behavior and ensure a positive experience for all users.",
  },
  {
    question: "Can businesses join Golvia?",
    answer:
      "Absolutely! Sports-related businesses can create profiles to connect with athletes and promote their products and services. This feature helps businesses reach their target audience within the sports community.",
  },
  {
    question: "How can I provide feedback or suggestions for Golvia?",
    answer:
      "We value user feedback! You can send your suggestions or inquiries through our contact form on the website or by reaching out to our support team at support@gol-via.com.",
  },
  {
    question: "When will Golvia be officially launched?",
    answer:
      "We are excited to announce that Golvia is set to launch on December 1, 2024. Stay tuned for updates and be among the first to join our community!",
  },
  {
    question: "Can monetization be added on Golvia?",
    answer:
      "Yes, monetization features will be added to Golvia in the future. We recognize the importance of providing opportunities for athletes and content creators to generate income from their engagements on the platform. This may include options like sponsored content, partnerships with brands, merchandise sales, and premium membership subscriptions. We are committed to evolving Golvia to meet the needs of our users and create a sustainable ecosystem that benefits the sports community. Stay tuned for updates on these exciting features!",
  },
];
