import PersonalInformation from "./PersonalInformation";
import { SectionKeys } from "../useSectionHook";
import { useState } from "react";
import ContactInformation from "./ContactInformattion";
import TeamInformation from "./TeamInformation";
import RecruitmentProcess from "./RecruitmentProcess";

interface Props {
  currentSectionKey: SectionKeys<"TEAM">;
  onNext: () => void;
  onPrevious: () => void;
  onComplete: () => void;
}

const Club = (props: Props) => {
  const { currentSectionKey, onNext, onPrevious, onComplete } = props;

  const [personalInfo, setPersonalInfo] = useState(null);
  const [professionalIfo, setProfessionalInfo] = useState(null);
  const [reference, setreference] = useState(null)

  const handlePersonalData = (data: any) => {
    setPersonalInfo(data);
  };
  const handleClubData = (data: any) => {
    setProfessionalInfo(data);
  };
  const handlePreferenceData = (data: any) => {
    setreference(data);
  };


  return (
    <>
      {/* Form 1 */}
      {currentSectionKey == "personalInfo" && (
        <PersonalInformation
        onNext={onNext}
        onSubmitFormData={handlePersonalData}
        />
      )}
      {/* Form 2 */}
      {currentSectionKey == "contactInfo" && (
        <ContactInformation
        onNext={onNext}
        onPrevious={onPrevious}
        onSubmitFormData={handleClubData}
        />
      )}
      {/* Form 3 */}
      {currentSectionKey == "teamInfo" && (
        <TeamInformation
        onNext={onNext}
        onPrevious={onPrevious}
           onSubmitFormData={handlePreferenceData}
           />
      )}
      {/* Form 3 */}
      {currentSectionKey == "preference" && (
        <RecruitmentProcess
          onComplete={onComplete}
          onPrevious={onPrevious}
          personalInfo={personalInfo}
          professionalIfo={professionalIfo}
          reference={reference}
   
        />
      )}
    </>
  );
};

export default Club;
