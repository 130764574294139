import AccessLayout from "layouts/AccessLayout";
import { PATHS } from "Routes/routes.path";
import { Helmet } from "react-helmet-async";
import { Avatar, Stack, useMediaQuery, useTheme } from "@mui/material";
import Avatar1 from "assets/dummy_avatar_img/avatar_img.svg";
import CircularLoader from "components/loaders/CircularLoader";
import { FaCircleCheck } from "react-icons/fa6";
import useSectionHook from "./useSectionHook";
import Athletes from "./Athelete";
import Scout from "./Scout";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProfileCompleted from "./ProfileCompleted";
import useAuthDetails from "pages/auth/useAuthDetails";
import { ProfileSectionKeysMap } from "pages/auth/RegistrationType";
import Club from "./Club";
import Fanbase from "./Fanbase";
import { convertToTitleCase } from "pages/network/GlobalNetwork";

const GetStarted = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { firstName, lastName, profilePicture } = useAuthDetails();
  const profileType = (useAuthDetails().profileType ||
    "ATHLETES") as keyof ProfileSectionKeysMap;

  const {
    sections,
    currentSectionKey,
  } = useSectionHook(profileType); // User data can be added to the hook to provide profile type

  const location = useLocation();

  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sectionParams = searchParams.get("section");
    if (sectionParams === "completed") {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  }, [location]);

  const pType = {
    ATHELETE: "ATHLETES",
    SCOUT: "SCOUT",
    CLUB: "TEAM",
    FANBASE: "FANBASE",
  };


  return (
    <AccessLayout path={PATHS.GET_STARTED}>
      <Helmet>
        <title>Get Started | Profile | Golvia</title>
      </Helmet>
      <Stack direction={{ xs: "column", md: "row" }} paddingY={4} spacing={{ xs: 3, lg: 4 }}>
        {!isMobile && (
          <div className=" w-1/4 h-full">
            <div className="bg-white rounded-2xl"  style={{ border:'0.5px solid #dfdeda '}}>
              <div className="px-8 pt-8">
                <Avatar src={ profilePicture || Avatar1} alt="avatar" sx={{ width: 56, height: 56 }} />
                <div className="space-y-1 my-4">
                  <h2 className="font-medium text-xl">
                    {firstName} {lastName}
                  </h2>
                  <h4 className="text-sm">{profileType === "ATHLETES" ? "Athlete" : convertToTitleCase(profileType) || ""}</h4>
                </div>
              </div>
              <hr />
              <div className="px-8 pb-8 mt-4 flex items-start justify-between w-full">
                <div className="space-y-2">
                  <h5 className="text-[#99A4BA]">Profile Level</h5>
                  {/* <button className="rounded-xl border text-primary border-primary px-3 py-2">
                    Complete Profile
                  </button> */}
                </div>
                <CircularLoader value={isCompleted ? 100 : 40} />
              </div>
            </div>
            <div className="mt-2 p-6 rounded-2xl bg-white"  style={{ border:'0.5px solid #dfdeda '}}>
              <h3 className="text-base mb-6">My Activity Stats</h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <h4 className="text-[#7D7D7D]">Profile Views</h4>
                  <h5 className="text-primary">--</h5>
                </div>
                <div className="flex items-center justify-between">
                  <h4 className="text-[#7D7D7D]">Post Impressions</h4>
                  <h5 className="text-primary">--</h5>
                </div>
                <div className="flex items-center justify-between">
                  <h4 className="text-[#7D7D7D]">Reel Impressions</h4>
                  <h5 className="text-primary">--</h5>
                </div>
              </div>
            </div>
          </div>
        )}

        <Stack
          width={{ xs: "100%", lg: "75%" }}
          borderRadius="16px"
          paddingY={{ xs: 0, md: 4 }}
          className="bg-white"
          sx={{ border:'0.5px solid #dfdeda '}}
        >
          {/* Nav */}
          {isCompleted ? (
            <>
              <ProfileCompleted />
            </>
          ) : (
            <>
              {!isMobile && (
                <>
                  <Stack
                    direction="row"
                    spacing={{ xs: 4, lg: 8 }}
                    paddingX={4}
                    paddingY={3}
                  >
                    {Object.entries(sections).map(
                      ([key, { label, completed }], index) => (
                        <Stack
                          key={key}
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          {!completed ? (
                            <div
                              className={`rounded-full ${
                                currentSectionKey === key
                                  ? "text-white bg-primary"
                                  : "text-primary bg-[#EBF4FF] hover:bg-[#C5E1F5]"
                              }  h-8 w-8 flex items-center justify-center`}
                            >
                              {++index}
                            </div>
                          ) : (
                            <FaCircleCheck className="text-nonenary text-3xl" />
                          )}
                          <div className="text-xs">{label}</div>
                        </Stack>
                      )
                    )}
                  </Stack>
                  <hr />
                </>
              )}

              {pType.ATHELETE === profileType && <AtheleteComponent />}
              {pType.SCOUT === profileType && <ScoutComponent />}
              {pType.CLUB === profileType && <TeamComponent />}
              {pType.FANBASE === profileType && <FanBaseComponent />}
            </>
          )}
        </Stack>
      </Stack>
    </AccessLayout>
  );
};

export default GetStarted;

const AtheleteComponent = () => {
  const {
    currentSectionKey,
    onNext,
    onPrevious,
    onComplete,
  } = useSectionHook("ATHLETES");
  return (
    <Athletes
      currentSectionKey={currentSectionKey}
      onNext={onNext}
      onPrevious={onPrevious}
      onComplete={onComplete}
    />
  );
};
const ScoutComponent = () => {
  const {
    currentSectionKey,
    onNext,
    onPrevious,
    onComplete,
  } = useSectionHook("SCOUT");
  return (
    <Scout
      currentSectionKey={currentSectionKey}
      onNext={onNext}
      onPrevious={onPrevious}
      onComplete={onComplete}
    />
  );
};
const TeamComponent = () => {
  const {
    currentSectionKey,
    onNext,
    onPrevious,
    onComplete,
  } = useSectionHook("TEAM");
  return (
    <Club
      currentSectionKey={currentSectionKey}
      onNext={onNext}
      onPrevious={onPrevious}
      onComplete={onComplete}
    />
  );
};
const FanBaseComponent = () => {
  const {
    currentSectionKey,
    onNext,
    onPrevious,
    onComplete,
  } = useSectionHook("FANBASE");
  return (
    <Fanbase
      currentSectionKey={currentSectionKey}
      onNext={onNext}
      onPrevious={onPrevious}
      onComplete={onComplete}
    />
  );
};
