import { selectToken } from "pages/auth/slice";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  // Access token from Redux store
  const token = useAppSelector(selectToken);

  // Optionally fallback to localStorage if token is not in Redux (this is up to your implementation)
  const user = token || window.localStorage.getItem("authToken"); // If there's a token in localStorage, fallback to it

  const location = useLocation(); // To remember where the user was trying to go

  // If no token, redirect to login page, passing the current location so they can be redirected back after login
  if (!user) {
    return <Navigate to="/login" state={{ redirectTo: location.pathname }} />;
  }

  // If token exists, render the protected route
  return children;
};

export default ProtectedRoute;
