import { Avatar, Box, Button, Stack, Typography, Modal } from "@mui/material";
import { useState } from "react";
import SettingsLayout from "layouts/SettingsLayout";
// import PersonalProfile from "./PersonalProfile";
// import ClubProfile from "./ClubProfile";
import useAuthDetails from "pages/auth/useAuthDetails";
import { Link } from "react-router-dom";
import { PATHS } from "Routes/routes.path";
import logo from "../../../assets/logo/logo-blue.svg";
import { PiPhoneLight } from "react-icons/pi";
import Icons from "constants/Icons";
import { convertToTitleCase } from "helpers/Formatters";
import ProfileDisplay from "./ProfileDisplay";
import { useGetPostsByCurrentUserQuery } from "pages/feeds/api";
import FeedItem from "pages/feeds/Post";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: 400, xs: 300 },

  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const ProfileSettings = () => {
  // const [isPersonal, setIsPersonal] = useState(true);

  // const handleTabChange = (personal: boolean) => {
  //   setIsPersonal(personal);
  // };

  const [open] = useState(true);
  const { data: posts } = useGetPostsByCurrentUserQuery({});

  const { firstName, lastName, profilePicture, sportType, profileType, isProfileCompleted, country, profileDetails, metadata } =
    useAuthDetails();

    const {connectionsCount, followersCount} = metadata || {}

  const {
    // address,
    currentClub,
    preferredPosition,
    dateOfBirth,
    height,
    weight,
    biography,
    profession,
    preferredClub,
    preferredFoot,

    // SCOUTS
    yearsOfExperience,
    scoutingExperienceYears,
    notableTalents,
    areasOfSpecialization,
    affiliatedOrganizations,
    scoutingRegion,
    certifications,
    preferredAttributes,
    regionsOfInterest,
    sports,
    // notesOnAthletes,
    position,
    ageGroup,
    scoutingHistory,
    phoneNumber,
    socialMediaLinks,
    city,

    // FANBASE
    favoriteSports,
    favoriteAthletes,
    notificationPreferences,
    interactions,
    purchasedItems,
    competitionLevel,

    // CLUB

    clubName,
    contactEmail,
    contactPersonName,
    contactPhone,
    website,
    socialLinks,
    recruitmentAreas,
    playerType,
    teamLogoUrl,
    players,
    clubAchievements,
    clubVacancies,
  } = profileDetails || {};

  const personalInformation = {
    preferredPosition,
    dateOfBirth,
    height,
    weight,
    yearsOfExperience,
    scoutingExperienceYears,
    notableTalents,
    areasOfSpecialization,
    favoriteSports,
    favoriteAthletes,
    notificationPreferences,
    clubName,
    contactEmail,
    contactPersonName,
    contactPhone,
  };

  const professionalInfo = {
    profession,
    preferredClub,
    preferredFoot,
    affiliatedOrganizations,
    scoutingRegion,
    certifications,
    preferredAttributes,
    regionsOfInterest,
    sports,
    interactions,
    purchasedItems,
    website,
    socialLinks,
    recruitmentAreas,
    playerType,
    teamLogoUrl,
    players,
  };

  const otherInformations = {
    position,
    ageGroup,
    scoutingHistory,
    socialMediaLinks,
    competitionLevel,
    clubAchievements,
    clubVacancies,
  };

  return (
    <>
      <SettingsLayout>
        <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" paddingTop={4}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                bgcolor: "#fff",
                borderRadius: "12px",
                boxSizing: "border-box",
   
              }}
            >

              <Box sx={{height:'100px', bgcolor:'#07123d', mx:-6, mt:-4, borderRadius:'12px 12px 0px 0px'}}>

              </Box>
              <Box sx={{display:'flex', justifyContent:'space-between'}}>

                            <div className="flex flex-col ">
                <Avatar src={profilePicture} sx={{ width: "100px", height: "100px" , mt:-6, border:'3px solid #fff'}} />
                <div className="mt-3">
                  <p className="font-[500] text-[20px]">
                    {firstName} {lastName}
                  </p>
                  <p className="text-gray-400 text-[14px]">
                    {convertToTitleCase(profileType || "")} - {sportType}
                  </p>
                </div>
                        <div className="mt-3 rounded-[4px] flex items-center justify-center w-fit  gap-2 ">
                  {country && (
                    <p className="text-[14px]">
                      {country}
                      <span style={{ marginLeft: "9px" }}>|</span>{" "}
                    </p>
                  )}
                       <Link to={`${PATHS.NETWORK}?tab=mynetwork`}>
                  <p className="text-[14px] hover:underline cursor-pointer hover:text-primary">{connectionsCount} Connections </p></Link> .{" "}
                          <Link to={`${PATHS.NETWORK}?tab=mynetwork`}>
                  <p  className="text-[14px] hover:underline cursor-pointer hover:text-primary">{followersCount} Followers</p></Link>
                </div>
       
              </div>
                <div>
                           {country ||
                  (city && (
                    <div className="mt-3 rounded-[4px] bg-blue-50 flex items-center justify-center w-fit p-1 gap-2 px-4 ">
                      <img src={Icons.location} alt="location" />
                      <p className="text-[14px]">{country || city}</p>
                    </div>
                  ))}
                {currentClub && (
                  <div className="mt-3 rounded-[4px] bg-blue-50 flex items-center justify-center w-fit p-1 gap-2 px-4 ">
                    <img src={Icons.shirt} alt="location" />
                    <p className="text-[14px]">{currentClub}</p>
                  </div>
                )}
                {phoneNumber && (
                  <div className="mt-3 rounded-[4px] bg-blue-50 flex items-center justify-center w-fit p-1 gap-2 px-4 ">
                    <PiPhoneLight />
                    <p className="text-[14px]">{phoneNumber}</p>
                  </div>
                )}
                </div>
              </Box>
       
         
         
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                borderRadius: "12px",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "space-between",
                mt: 3,
                columnGap: 4,
                rowGap: 2,
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <div className="pt-5 pb-6  md:w-[35%]">
                {/* Personnal Info */}
                <p className="text-black font-[500]">Personal information</p>
                <hr className="my-3" />
                <ProfileDisplay profileDetails={personalInformation} />

                {/* Professional Information */}
                <div className="mt-8">
                  <p className="text-black font-[500]">Professional information</p>
                  <hr className="my-3" />

                  <ProfileDisplay profileDetails={professionalInfo} />
                </div>

                {/* Dream Goal / Ambition */}
                <div className="mt-8">
                  <p className="text-black font-[500]">Other Informations</p>
                  <hr className="my-3" />
                  <ProfileDisplay profileDetails={otherInformations} />

                  {biography && (
                    <div className="mt-4 bg-blue-50 p-4 rounded-md">
                      <p>Bio</p>
                      <p className="text-[14px] text-black">{biography}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="md:w-[65%] md:border-l-[1px] py-4 md:pl-6">
                <Typography variant="h$20" fontWeight="medium" color="black" pb={2}>
                  All Posts
                </Typography>
                      {posts?.data && posts?.data?.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          height: "200px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{fontWeight:500, textAlign:'center'}}>
                          {firstName} hasn’t posted yet

                        </Typography>
                        <Typography sx={{fontSize:'12px', textAlign:'center'}}>
                Recent posts {firstName}  shares will be displayed here.

                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                {posts?.data && posts?.data.map((post) => <FeedItem key={post.id} post={post} compact />)}
                       
                    </>
                  )}
              </div>
            </Box>
          </Box>
        </Stack>
      </SettingsLayout>
      {!isProfileCompleted && (
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              background: `url(${logo})`,
              backgroundRepeat: "no-repeat",
              bgcolor: "#fff",
              backgroundPositionX: "300px",
              backgroundPositionY: "80px",
              backgroundSize: "200px",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              Profile Level <span className="text-primary font-[600]">40% of 100</span>
              <br />
              Complete your profile to start using Golvia app
            </Typography>
            <Link to={PATHS.GET_STARTED}>
              <Button variant="contained" sx={{ mt: 3 }}>
                Get Started
              </Button>
            </Link>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ProfileSettings;
