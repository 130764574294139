import axios from 'axios';

const apiClient = axios.create({
  baseURL: "https://www.thesportsdb.com/api/v1/json/3/", // API endpoint

});



export interface LiveScore {
  fixture: {
    id: number;
    date: string;
  };
  league: {
    id: number;
    name: string;
    country: string;
  };
  teams: {
    home: {
      name: string;
    };
    away: {
      name: string;
    };
  };
  goals: {
    home: number;
    away: number;
  };
}

export const fetchLiveScores = async (country: string, league: string) => {
 const response = await apiClient.get('searchteams.php?t=Arsenal'
 );

  const allScores = response.data.response as LiveScore[];

  // Filter by country and league
  return allScores.filter(
    score =>
      score.league.country.toLowerCase() === country.toLowerCase() &&
      score.league.name.toLowerCase() === league.toLowerCase()
  );
};

