import { Stack, StackOwnProps, Typography } from "@mui/material";
import human from "utils/human";
import { ReactComponent as LikeIcon } from "assets/icons/like-icon.svg";

export default function PostCounts({
  likes,
  comments,
  onDetailsClick,
  ...stackProps
}: { likes: number; comments: number; onDetailsClick?: () => void } & StackOwnProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" {...stackProps}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        visibility={!!likes ? "visible" : "hidden"}
      >
        <LikeIcon aria-label="like" />
        <p>{human(likes)}</p>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1} className="cursor-default">
        <Typography
          variant="p$14"
          visibility={!!comments ? "visible" : "hidden"}
          onClick={onDetailsClick}
        >
          {comments} Comments
        </Typography>
      </Stack>
    </Stack>
  );
}
