import { Avatar, Box, Typography } from "@mui/material";
// import { Link } from "react-router-dom";
// import { DummyAgentData, DummyClubData } from "helpers/dummydata";
// import { HiCheckBadge } from "react-icons/hi2";
import Avartar1 from "assets/dummy_avatar_img/avatar_img.svg";
import Avartar2 from "assets/dummy_avatar_img/avatar_img2.svg";
import Avartar3 from "assets/dummy_avatar_img/avatar_img3.svg";
import Avartar4 from "assets/dummy_avatar_img/avatar_mg4.svg";
import { TbPlayerPlayFilled } from "react-icons/tb";
import useAuthDetails from "pages/auth/useAuthDetails";
// import { getTopAgents, getTopAtheletes, getTopClubs } from "api";
// import { useGetApi } from "api/hooks/useGetApi";
// import { UserListSkeleton } from "./GlobalNetwork";
// import images from "constants/images";
// import { MdChevronRight } from "react-icons/md";
import { useState, useEffect } from "react";
import { fetchLiveScores, LiveScore } from "api/livescore";
// import { RxDividerVertical } from "react-icons/rx";

export default function GlobalNetworkWidget() {
  const [scores, setScores] = useState<LiveScore[]>([]);
  const [country, setCountry] = useState<string>("England");
  const [league, setLeague] = useState<string>("Premier League");

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const getFilteredScores = async () => {
      try {
        const data = await fetchLiveScores(country, league);
        setScores(data);
      } catch (error) {
        console.error("Error fetching live scores:", error);
      }
    };
    getFilteredScores();
  }, [country, league]);

  const {asset} = useAuthDetails() 

  console.log(country, league, "Leadgue");
  return (
    <>
      <div className="bg-white rounded-2xl  max-h-[400px] overflow-hidden " style={{ border:'0.5px solid #dfdeda '}}>
        {/* <div className="pt-5 flex items-center justify-between ">
          <p className="font-[500] text-[16px]">LiveScore</p>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#F3F6FC",
              columnGap: 2,
              px: 2,
              py: 0.4,
            }}
          >
            <img
              src={
                "https://cdn.britannica.com/44/344-050-94536674/Flag-England.jpg"
              }
              alt="country_flag"
              width={20}
            />
            <Typography sx={{ fontSize: "12px" }}>EngLand</Typography>
          </Box>
        </div> */}
{/* <LiveScoreWidget/> */}


   <div
      id="awo_w7174_674aacfd8bc37"
      className="livescore-widget hide_scrollbar"
      style={{ margin: 0, padding: 0, overflow: "scroll", }}
    >
      <iframe
        data-widget="awo_w7174_674aacfd8bc37"
        data-hj-allow-iframe="true"
        src="https://ls.soccersapi.com?w=awo_w7174_674aacfd8bc37"
        width="100%"
        height="1700"
        frameBorder="0"
        className="widget hide_scrollbar"
      
        style={{ overflow: "scroll", height: "1000px", width: "100%", scrollbarWidth:'none' }}
        title="Live Score Widget"
      ></iframe>
    </div>


        {/*  */}
        {/* <div
          className={`bg-gray-100 mt-5 ${
            expanded ? "h-[300px]" : "h-[40px]"
          } transition-all `}
        >
          <div
            className=" p-3 flex justify-center items-center cursor-pointer"
            onClick={() => setExpanded(!expanded)}
          >
            <p className="text-center text-[12px] font-[600]">
              Premiere League
            </p>
            <MdChevronRight />
          </div>
        </div> */}

        {/* <div className="p-2 flex justify-between items-center">
          <div className="flex items-center gap-x-2"> 
<p className="text-[10px] font-[500]">Manchester United</p>
<p className="text-[10px] font-[500]">0</p>

          </div>
          <RxDividerVertical />
        <div className="flex items-center gap-x-2"> 
<p className="text-[10px] font-[500]"> 1</p>
<p className="text-[10px] font-[500]"> Manchester United</p>

          </div>
        </div>
        <div className="p-2 flex justify-between items-center">
          <div className="flex items-center gap-x-2"> 
<p className="text-[10px] font-[500]">Manchester United</p>
<p className="text-[10px] font-[500]">0</p>

          </div>
          <RxDividerVertical />
        <div className="flex items-center gap-x-2"> 
<p className="text-[10px] font-[500]"> 1</p>
<p className="text-[10px] font-[500]"> Manchester United</p>

          </div>
        </div> */}

      </div>
 <div className="bg-white rounded-2xl mt-2 py-4 px-4 pb-6" style={{ border:'0.5px solid #dfdeda '}}>
        <div>
          <h2 className="text-sm font-medium">
            Try Glovia{" "}
            <span className="bg-[#FFF0D0] text-secondary ml-1 py-1 px-2 rounded-2xl">
              Premium
            </span>
          </h2>
          <h3 className="mt-2 text-2xl font-medium">
            Reach unlimited audience
          </h3>
          <h4 className="mt-1 text-octenary font-normal text-sm">
            Through video uploads
          </h4>
        </div>
        <div className="mt-2 flex items-center">
          <div className="relative w-2/3">
            <div className="bg-white rounded-full p-[0.3px] inline-flex">
              <Avatar
                src={
                  asset?.profilePictureUrl ? asset?.profilePictureUrl : Avartar1
                }
                alt={"a user"}
                sx={{ width: 100, height: 100 }}
              />
            </div>
            <div className="rounded-full  inline-flex absolute top-0 right-[60px] z-50">
              <Avatar
                src={Avartar4}
                alt={"a user"}
                sx={{ width: 30, height: 30 }}
              />
            </div>
            <div className="bg-white rounded-full p-1 inline-flex absolute top-9 left-24 z-50">
              <div className="bg-gray-200 w-8 h-8 rounded-full inline-flex items-center justify-center">
                <TbPlayerPlayFilled className="text-[#FF6C6C]" />
              </div>
            </div>
            <div className="rounded-full inline-flex absolute top-11 right-6 z-50">
              <Avatar
                src={Avartar2}
                alt={"a user"}
                sx={{ width: 20, height: 20 }}
              />
            </div>
           
          </div>
          <button className="bg-secondary text-white rounded-2xl py-2 px-3 text-sm font-medium">
            Get Started
          </button>
        </div>
      </div>

    </>
  );
}


